@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '../../@core//scss/base/colors.scss';

.react-dataTable > div {
  overflow-x: auto;
}

.active-header-card {
  background: linear-gradient(118deg, #0377b166, rgba(0, 124, 186, 0.2)) !important;
  border-radius: 0.428rem;
}

body {
  padding-right: 0 !important;
}

.rdt_TableHeadRow {
  height: 4rem !important;
}

.rdt_TableCol > div > div {
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
}

.main-menu .navbar-header .navbar-brand {
  margin-top: 1.6rem;
}

.nav-item a {
  display: flex !important;
  align-items: center !important;
}

// Scrollbar
.simplebar-scrollbar::before {
  background-color: #007CBA !important;
  opacity: 0.4 !important; 
}

.mandatory-indicator {
  margin: 0 3px;
}

.form-row-gap {
  row-gap: 8px;
}

.tenant_navigation {
  font-family: 'Montserrat', Helvetica, Arial, serif !important;
  color: #7DCBEC !important;
  text-transform: none !important;
  font-size: 1rem !important;
}

.view-details {
  &__button {
    display: flex;
    justify-content: start;
  }
}

.detail-wrapper {
  display: flex;
  align-items: end;
  margin-bottom: 0.5rem;
}

.tag-dropdown {
  padding: 5px;
  background-color: #202638;
  font-weight: 500;
  position: relative;

  &-title {
    @extend .tag-dropdown;
    position: sticky;
    top: -5px;
    font-size: 16px;
  }

  &-subtitle {
    @extend .tag-dropdown;
    position: sticky;
    top: 28px;
    font-size: 14px;
  }

  &.light {
    background-color: #eee;
  }
}

.select__menu-list::-webkit-scrollbar {
  width: 8px;
  height: 0px;
  border: none;
  outline: none;
}
.select__menu-list::-webkit-scrollbar-track {
  background: transparent
}
.select__menu-list::-webkit-scrollbar-thumb {
  background: $primary;
}
.select__menu-list::-webkit-scrollbar-thumb:hover {
  background: #25a7de;
}

.md-flex-direction-row-reverse {
  flex-direction: row;
  
  @include media-breakpoint-down(md) {
  flex-direction: row-reverse;
  }
}

.accordion-collapse {
  .accordion-body {
    max-height: fit-content;
  }
}

.custom-accordion-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  gap: 20px;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }
}

.sm-flex-direction-column-start {
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.md-flex-direction-column-reverse {
  @include media-breakpoint-down(md) {
    flex-direction: column-reverse;
  }
}

.hide-md-down {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.display-md-down {
  display: none;
  @include media-breakpoint-down(md) {
    display: inline !important;
  }
}

.flatpickr-monthSelect-months {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: fit-content;
}

.flatpickr-monthSelect-month {
  padding: 5px 10px;
  background-color: #fff;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
}

.mr-50-md {
  margin-right: 5px;

  @include media-breakpoint-down(md) {
    margin-right: 0px;
  }
}

.form-group {
  margin-bottom: 1em !important;
}

.flatpickr-backgroundColor-white {
  background-color: #fff !important;
}

.no-border {
  border: 0 !important;
}

.latest-announcements-list-items {
  margin: 0;

  * {
    margin: 0;
    display: inline;
  }
}

.data-table-selected-row {
  background-color: #c8e5ff !important;

  a {
    color: #000 !important;
  }

  div {
    color: #7b6b6b !important;
  }
}

.color-black {
  color: #000000de !important;
}