@import '../scss/style.scss';

.file-input-translation-container {
  &:hover {
    background-color: rgba(230, 232, 233, 0.5);
  }
}
.file-input-translation-label {
  padding: 8px 16px;
  border: 1px solid rgb(216, 214, 222);
  border-radius: 4.998px 0 0 4.998px;
  color: rgb(110, 107, 123);
  cursor: pointer;
  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-size: 14px;
  line-height: 20.3px;
  height: 38.2656px;
  min-width: fit-content;
  box-sizing: border-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.file-input-translation-file {
  padding: 8px 16px;
  border: 1px solid rgb(216, 214, 222);
  border-width: 1px 1px 1px 0;
  border-radius: 0 4.998px 4.998px 0;
  color: rgb(110, 107, 123);
  cursor: pointer;
  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-size: 14px;
  line-height: 20.3px;
  height: 38.2656px;
  box-sizing: border-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: #fff !important;
}
