@import '../../../base/bootstrap-extended/include';
@import '../../../base/components/include';

.dropzone {
  display: flex;
  cursor: pointer;
  min-height: 200px;
  align-items: 'center';
  justify-content: center;
  background-color: transparent;
  border: 1.4px dashed $primary;
  border-radius: $border-radius;
}

.dark-layout {
  .dropzone {
    background-color: transparent;
  }
}
