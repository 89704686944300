.fc-day-today {
  background-color: #1976d2 !important;
  color: #fff;
}

.calendar-wrapper-dark {
  .calendar-absence-justified{
    background-color: #E36414 !important;
  }
  
  .calendar-absence-unjustified{
    background-color: #8A2BE2 !important;
  }

  .calendar-absence-rest{
    background-color: #ad1ba1 !important;
  }
  
  .fc-day-sun, .fc-day-sat{
    background-color: #283046;
  }

  .calendar-non-working-day {
    background-color: #BF3131 !important;
    color: #fff;
  }
}

.calendar-wrapper-light{
  .calendar-absence-justified{
    background-color: #E36414 !important;
    color: #fff
  }
  
  .calendar-absence-unjustified{
    background-color: #8A2BE2 !important;
    color: #fff;
  }

  .calendar-absence-rest{
    background-color: #ad1ba1 !important;
  }
  
  .fc-day-sun, .fc-day-sat{
    background-color: #ccc;
  }

  .calendar-non-working-day {
    background-color: #BF3131 !important;
    color: #fff;
  }
}

.legend-dark {
  display: flex;
  gap: 20px;

  .color-box {
    width: 20px;
    height: 15px;
    border: .5px solid #fff;
    border-radius: 2px;
  }

  .holiday {
    background-color: #BF3131;
  }

  .justified-absence{
    background-color: #E36414;
  }

  .unjustified-absence {
    background-color: #8A2BE2;
  }

  .rest-absence {
    background-color: #ad1ba1 !important;
  }

  .weekend {
    background-color: #283046;
  }

  .today {
  background-color: #1976d2;
  }
}

.legend-light {
  display: flex;
  gap: 20px;

  .color-box {
    width: 20px;
    height: 15px;
    border: .5px solid #444;
    border-radius: 2px;
  }

  .holiday {
    background-color: #BF3131;
  }

  .justified-absence{
    background-color: #E36414;
  }

  .unjustified-absence {
    background-color: #8A2BE2;
  }

  .rest-absence {
    background-color: #ad1ba1 !important;
  }

  .weekend {
    background-color: #ccc;
  }

  .today {
    background-color: #1976d2;
  }
}

.legend-item {
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 10px
}

.fc-event {
  white-space: normal;
}

.fc-event-main {
  height: 100%;
}

.fc-daygrid-day-frame.fc-scrollgrid-sync-inner{
  height: 70px;
  display: flex;
  flex-direction: row-reverse;
  word-wrap: break-word;
}

.fc-daygrid-day-events {
  width: 100%;
}

.fc-daygrid-day {
  position: relative;
}

.fc-button-primary {
  color: #7DCBEC !important;
  background-color: transparent !important;
  border: 1px solid #7DCBEC !important;
  box-shadow: none !important;
}
