@import '../scss/style.scss';

.album-wrapper {
  height: 300px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-top: 28px;

  .album-image{
    width: 100%; 
    height: 100%;
    border-radius: 10px;
  }
  
  .album-title {
    padding: 10px;
    position: absolute;
    background-color: rgba(0 , 0, 0, 0.5);
    width: 100%;
    height: 300px;
    left: 0;
    top: 230px;
    transition: all 0.3s ease;
    overflow-y: none;
    cursor: pointer;

    h3 {
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    h5 {
      color: #fff;
    }

    p {
      margin-top: 10px;
      color: #fff;
    }

    .tenant-group-item{
      margin-right: 5px;
      margin-bottom: 5px;
      padding: 2px 5px;
      border-radius: 10px;
      color: #fff;
      width: fit-content;
      display: inline-block;
      font-size: 12px;
      background-color: rgba(255,255,255, 0.3);
    }
  }

  &.hovered {
    .album-title{
      top: 0;
      background-color: rgba(0 , 0, 0, 0.9);
    }
  }
}

.album-preview-wrapper {
  width: 250px;
  height: 250px;
  min-width: 250px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  position: relative;

  .background-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.hovered {
    .item{
      opacity: 1;
      transform: translateY(0);
    }
  }

  @include media-breakpoint-down(sm){
    width: calc(50% - 10px);
    height: 100%;
    min-width: 0px;
    aspect-ratio: 1 / 1;
  }

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;  
    height: 100%;
    background: rgba(0,0,0, 0.8);
    opacity: 0;
    transition: all 0.3s;
    gap: 20px;
    border-radius: 10px;
    transform: translateY(100%);

    @include media-breakpoint-down(sm){
      gap: 10px;
    }
  }
}
